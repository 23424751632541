import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Services from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import ServicesDetails from '@solid-ui-blocks/Faq/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import CompaniesPhoto from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import JoinCompanies from '@solid-ui-blocks/Content/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Team from '@solid-ui-blocks/Hero/Block03'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_case-study-details-styles'

const CaseStudyDetails = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props} allBlockContent={allBlockContent}>
      {/* TODO: Change SEO based on case study page */}
      <Seo title='Case Studies' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
        <Divider space='4' />
        <Container variant='cards.paper-lg' sx={styles.servicesContainer}>
          <Services content={content['services']} />
        </Container>
      </Container>
      <Divider space='5' />
      <ServicesDetails content={content['services-details']} />
      <Divider space='5' />
      <CompaniesPhoto content={content['companies-photo']} />
      <Divider space='5' />
      {/* <JoinCompanies content={content['companies-join']} /> */}
      <Container variant='wide' sx={styles.contentTwoContainer}>
        <Content content={content['case-study-cta']} />
      </Container>
      <Divider space='4' />
      <Companies content={content['companies']} />
      {/* <Divider space='4' />
      <Contact content={content['cta']} /> */}
      <Divider space='4' />
      <Team content={content['contact-footer']} />
    </Layout>
  )
}

export const query = graphql`
  query caseStudyDetailsBlockContent($page: String) {
    allBlockContent(
      filter: { page: { in: [$page, "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default CaseStudyDetails
